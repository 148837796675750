import styled from "styled-components";
import px2vw from "../../utils/px2vw";


export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: ${px2vw(32)};
  max-width: 100%;

  margin-top: ${px2vw(150)};
    
  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
  
`;

export const Box = styled.div`
  display: flex;
  width: ${px2vw(320, 320)};
  min-height: ${px2vw(200, 320)};
  flex-direction: column;
  padding: ${px2vw(50)};
  margin: ${px2vw(20)};
  background-color: ${props => props.bgColor};
  height: 100%;
  

  @media (min-width: 300px) {
    width: ${px2vw(300, 568)};
    min-height: ${px2vw(200, 768)};
    height: 100%;
  }
`;


//@media only screen and (min-width: 768px) {
export const Form = styled.form`
  /*MONITOR*/
  @media (min-width: 1024px) {
    width: ${px2vw(220, 808)};
    min-height: ${px2vw(170, 768)};
    height: 100%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: ${px2vw(320, 768)};
    min-height: ${px2vw(200, 768)};
    height: 100%;
  }

  @media (max-width: 767px) {
    width: ${px2vw(320, 408)};
    min-height: ${px2vw(200, 768)};
    height: 100%;
  }
 

    background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    margin: 10px 0 40px;
  }
  p {
    color: #ff3333;
    margin-bottom: 15px;
    border: 1px solid #ff3333;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  
  input {
            flex: 1;
            height: 70px;
            margin-bottom: 15px;
            padding: 8px 20px;
            color: #777;
            font-size: 15px;
            width: 100%;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #dadada;
            border-radius: 10px;
            font-family: Arial, FontAwesome;
            &::placeholder {
              color: #999;
            }
  }

  
  button {
    color: #fff;
    font-size: 16px;
    background: #060606;
    height: 56px;
    border: 0;
    border-radius: 23px;
    width: 100%;
  }
  
  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }
  a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
  }

  .footerLogin {
    text-align: center;
    margin-top: 150px;
    font-size: 12px;
  }
  
`;
