import px2vw from "../utils/px2vw";

import "font-awesome/css/font-awesome.css";

`


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
}

:root {
  font-size: ${px2vw(24)};

  @media (min-width: 768px) {
    font-size: ${px2vw(18)};
  }

  @media (min-width: 1024px) {
    font-size: ${px2vw(16)};
  }
}

body, html {
  background: #FFF;
  font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  height: 100%;
  width: 100%;
}
`;
