import React, { useState } from "react";
import Select from "react-select";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from "react-query";
import api from "../../../services/api";
import { colourStyles } from "../Css/selectMulti";
import { pecasContext } from "../../../contexts/PecasContext";
import { getPecasContext } from "../../../contexts/GetPecasContext";

export const FiltrosEstoque = () => {
  const {
    handleGroupChange,
    handleUnidadeChange,
    selectedGroupId,
    handleMatPrimaChange,
    selectedMatPrima,
    isClearable,
  } = pecasContext();

  const {
    listUnidades,
    isLoadingUnidades,
    grupoMaquina,
    isLoadingMaquina,
    grupoMateria,
    isLoadingGrupo,
  } = getPecasContext();

  const options = {
    unidade: listUnidades
      ? listUnidades.map((item) => ({
          value: item.cgcCpf,
          label: item.fantasia,
        }))
      : "",
    maquinas: grupoMaquina
      ? grupoMaquina.map((item) => ({
          value: item.id,
          label: item.descricao,
        }))
      : [],

    grupoMatPrima: grupoMateria
      ? grupoMateria.map((item) => ({
          value: item.grupo,
          label: item.descricao,
        }))
      : [],
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: isMobile ? "1fr" : "repeat(3, 1fr)",
        gap: 2,
      }}
    >
      <Box>
        <Typography>Empresa</Typography>
        <Select
          options={options.unidade}
          isClearable={isClearable}
          onChange={handleUnidadeChange}
          isLoading={isLoadingUnidades}
          blurInputOnSelect={false}
          captureMenuScroll
        />
      </Box>
      <Box>
        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "150px",
          }}
        >
          Grupo de Máquinas
        </Typography>
        <Select
          closeMenuOnSelect={false}
          isMulti
          options={options.maquinas}
          styles={colourStyles}
          value={selectedGroupId.map((id) =>
            options.maquinas.find((option) => option.value === id)
          )}
          onChange={handleGroupChange}
          isLoading={isLoadingMaquina}
          blurInputOnSelect={false}
          captureMenuScroll
        />
      </Box>

      <Box>
        <Typography>Grupo de Materiais</Typography>
        <Select
          closeMenuOnSelect={false}
          isMulti
          options={options.grupoMatPrima}
          styles={colourStyles}
          value={selectedMatPrima.map((id) =>
            options.grupoMatPrima.find((option) => option.value === id)
          )}
          onChange={handleMatPrimaChange}
          isLoading={isLoadingGrupo}
          blurInputOnSelect={false}
          captureMenuScroll
        />
      </Box>
    </Box>
  );
};

export default FiltrosEstoque;
