
/**
 * Função responsavel pela forma que o menu treview se comporta.
 * No momento da renderização do Componente menu, o mesmo é iniciado ja 
 * e liberado para que possa ser usado os recurso de colapse dos grupos de menus
 * Esta definido no momento da Renderizacao do componente Menu entre o componentDidMount()
 */
export function loadTree() {


    let treeView;

    $(window).one('load.lte.treeview', function (e) {
        treeView = e;
    });

    var readyStateCheckInterval = setInterval(function () {
        if (document.readyState === "complete") {
            clearInterval(readyStateCheckInterval);

            if (treeView == undefined) {
                $('[data-widget="treeview"]').each(function () {
                    adminlte.Treeview._jQueryInterface.call($(this), 'init');
                })
            }
        }
    }, 1);


}

