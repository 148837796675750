import chroma from "chroma-js";
import Select from "react-select";

export const colourStyles = {
  control: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: "white",
    boxShadow: "60px",
    borderColor: "#ccc",
    overflowY: "scroll",
    maxHeight: 80,
    ":hover": {
      borderColor: "#000",
    },
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected
      ? "#347DDE"
      : isFocused
      ? chroma("#347DDE").alpha(0.1).css()
      : undefined,
    color: isSelected ? "white" : "black",
    cursor: "pointer",
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: chroma("#347DDE").alpha(0.1).css(),
    border: " 1px solid #5380D8",
    fontSize: 16,
    borderRadius: 6,
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 200,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#2E3052",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 200,
    title: data.label, // Adiciona o título para o tooltip
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#5380D8",
    ":hover": {
      backgroundColor: "#5380D8",
      color: "white",
    },
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 999,
  }),
};
