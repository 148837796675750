import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ChartMecanico = ({ mecanico, filtroTipo, dataEstatistica }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();

  const topMecanicos = mecanico.slice(0, mecanico.length);
  const total = topMecanicos.reduce(
    (accumulator, mec) => accumulator + mec.total,
    0
  );

  const [chartState] = React.useState({
    series: [
      {
        data: topMecanicos.map((dadosSetor) => {
          return `${dadosSetor.total} chamados /  ${dadosSetor.totalHorasAtendimento}h ${dadosSetor.totalMinutosAtendimento}min`;
        }),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
        events: {
          dataPointSelection: function (e, chart, opts) {
            if (opts.selectedDataPoints[0]) {
              const index = opts.selectedDataPoints[0];
              const mecanicoId = dataEstatistica[0].mecanicos[index].id;

              history.push(`/historicoMecanicos/${mecanicoId}`);
            }
          },
          dataPointMouseEnter: function (event) {
            event.target.style.cursor = "pointer";
          },
        },
      },
      plotOptions: {
        bar: {
          barHeight: "95%",
          borderRadius: 6,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },

      colors: ["#00E4E4"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#304758"],
          fontWeight: "normal",
          fontSize: isMobile ? "10px" : "14px",
        },
        formatter: function (val, opt) {
          if (filtroTipo != 0) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%";
          } else {
            return (
              opt.w.globals.labels[opt.dataPointIndex] +
              ": " +
              opt.config.series[0].data[opt.dataPointIndex]
            );
          }
        },
        offsetX: 0,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: topMecanicos.map((dadosSetor) => dadosSetor.usuario.nome),
        labels: {
          show: false,
        },
      },

      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: "ATENDIMENTOS POR MECÂNICOS",
        align: "center",
        floating: true,
        margin: 6,
        style: {
          height: "auto",
          fontSize: isMobile ? "14px" : "16px",
          fontFamily: "'Roboto', sans-serif",
        },
      },
      subtitle: {
        text: `Total: ${total.toFixed(0)}`,
        align: "right",
        margin: 10,
        offsetX: 3,
        style: {
          fontSize: isMobile ? "12px" : "16px",
          fontFamily: "'Roboto', sans-serif",
          fontWeight: "bold",
          height: "auto",
        },
      },

      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },

      /* responsive: [
        {
          breakpoint: 200,
          options: {
            plotOptions: {
              bar: {
                horizontal: true
              }
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ] */
    },
  });

  return (
    <Box sx={{ p: 2 }} className="shadow-lg bg-white rounded">
      <ReactApexChart
        options={chartState.options}
        series={chartState.series}
        type="bar"
        height={600}
      />
      {/*  <div id="html-dist"></div> */}
    </Box>
  );
};
